import React from 'react'
import Helmet from 'react-helmet'

const SectionPageMeta = (props) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'SiteNavigationElement',
    name: `IOL - ${props.section.split('/')[0][0].toUpperCase()}${props.section.split('/')[0].slice(1)}`,
    url: process.env.RAZZLE_SITE_URL + '/' + props.section,
    '@id': `${process.env.RAZZLE_SITE_URL}/${props.section}/#header`
  }
  return (
    <Helmet>
      {/* inline script elements */}
      <script type='application/ld+json' class='schemantra'>
        {`${JSON.stringify(schema)}`}
      </script>
    </Helmet>
  )
}
export default SectionPageMeta
