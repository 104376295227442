import React from 'react'
import { x } from '@xstyled/styled-components'
import useHasMounted from '../../lib/useHasMounted'
import { OutbrainWidget } from 'react-outbrain-widget'

const Lyftitec = (props) => {
  const hasMounted = useHasMounted()
  return (
    <>
      {hasMounted && <OutbrainWidget dataSrc={process.env.RAZZLE_SITE_URL + props.location.pathname} dataWidgetId='AR_20' />}
    </>
  )
}

export default Lyftitec
