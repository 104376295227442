import React from 'react'
import { x } from '@xstyled/styled-components'
import StyledLink from './ui/Link'
import cssfxUnderline from './ui/cssfxUnderline'
import { getSectionLabel } from 'ion-sections'
import opinionBanner from '../../../assets/images/general/iol-logo.png'

const OpinionSecondaryNav = ({ section, sectionLabel, relatedSections, visitorId, sessionId }) => {
  const clickSource = 'Secondary Nav'
  return (
    <x.div w='100%' maxWidth={1220} mx='auto' my={50} alignItems='baseline' flexWrap='wrap' position='relative' zIndex='1'>
      <x.div display='flex' ml={{ sm: '50px', lg: 'auto' }} mr={{ sm: 0, lg: 'auto' }} w='50%'>
        <x.img backgroundRepeat='no-repeat' w='120px' backgroundPosition='50%' backgroundSize={{ sm: 'contain', md: 'cover' }} src={opinionBanner} alt='IOL Opinion Banner' /> <x.p className='opinion-secondary-nav' fontWeight={100} text={{ sm: 'xlarge', lg: 'opinionHeading' }} mt={{ sm: 35, lg: 5 }} ml={{ sm: 5, lg: 10 }} color='white'>OPINION</x.p>
      </x.div>
      <x.div mt={50} mb={{ sm: 20, lg: 50 }} justifyContent='center' visibility='visible' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'min-content auto' }} gap={{ sm: '10', lg: '40' }}>
        <x.nav display='flex' flexDirection='row' gap={{ sm: 5, lg: 20 }} aria-label='Section Navigation' pb={{ sm: '20', article: '0' }} overflowX='auto'>
          {relatedSections && relatedSections.map(relatedSection => (
            <StyledLink key={relatedSection.id} to={'/' + relatedSection.id} text={{ sm: 'small', lg: 'h4' }} textTransform='uppercase' pl={{ sm: 10, lg: 20 }} color='white' hoverColor='meta' section={section} visitorId={visitorId} sessionId={sessionId} source={clickSource}>
              <cssfxUnderline.SweepIn hoverBGColor='orange' hoverColor='orange'>{relatedSection.label || getSectionLabel(relatedSection.id)}</cssfxUnderline.SweepIn>
            </StyledLink>
          ))}
          <x.button display={{ sm: 'none', lg: 'inherit' }} pl={9} pt={6} visibility={{ sm: 'hidden', lg: 'visible' }} textTransform='uppercase' fontWeight='medium' w={180} h={30} bg='red' mr={5}>
            <x.a href='https://docs.google.com/forms/d/e/1FAIpQLSepDqxvYjtEea-7Y32-P8-aNe2XAelF_UQDL9eeWk8L5KqJQA/viewform' target='_blank' color='white'>Submit an opinion</x.a>
          </x.button>
        </x.nav>
      </x.div>
      <x.div w='50%' mx='auto' display={{ sm: 'inherit', lg: 'none' }} visibility={{ sm: 'visibile', lg: 'hidden' }}>
        <x.button textTransform='uppercase' fontWeight='medium' w={180} h={50} bg='red' mr={5}>
          <x.a href='https://docs.google.com/forms/d/e/1FAIpQLSepDqxvYjtEea-7Y32-P8-aNe2XAelF_UQDL9eeWk8L5KqJQA/viewform' target='_blank' color='white'>Submit an opinion</x.a>
        </x.button>
      </x.div>
    </x.div>
  )
}

export default OpinionSecondaryNav
