import React from 'react'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch (error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('ErrorBoundary: ', error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      return <div>{this.props.message}</div>
    }
    return this.props.children
  }
}
export default ErrorBoundary
